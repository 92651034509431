import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  pageTitle: 'Frontend Developer React',
  introTitle: 'Frontend Developer React (m/f/d)',
  introInfo: '<p>freshcells systems engineering GmbH is a software service provider based in Düsseldorf with a dynamic team of over 40 creative experts from various fields.</p><p>We focus on authenticity, individual talent development and open communication. We build on respect, trust and responsibility. As an innovation-driven company, we invest in new ideas. We know that the best results are achieved as a team. We make mistakes, but we learn from them. We are open and courageous for new paths.</p><p>Join us on our exciting journey in what is probably the most important and forward-looking area - software development - as a Frontend Developer React (m/f/d)!</p>',
  responsibilitiesTitle: 'Your Responsibilities',
  responsibility1: 'You develop and style complex web applications in our environments using react.js, our component library and SCSS',
  responsibility2: 'You develop flexible reusable components',
  responsibility3: 'You continually contribute to improving our component library, architecture and design system',
  responsibility4: 'You bring creative input for improving the user experience (e.g., micro animations)',
  responsibility5: 'You contribute your knowledge to the team and learn from the team',
  qualificationsTitle: 'Your Qualifications',
  qualification1: 'At least three years provable experience as Frontend Developer',
  qualification2: 'Seasoned handling of react.js with at least 1 year of experience on react.js based projects',
  qualification3: 'Seasoned handling of HTML/JSX & SCSS',
  qualification4: 'Experience with UI component libraries like React Bootstrap or Material UI',
  qualification5: 'Experience with component-based react development',
  qualification6: 'Experience with react frontend project architecture',
  qualification7: 'Fluent communication skills in English',
  qualification8: 'Very high quality standards and a sense of responsibility',
})
