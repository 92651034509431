// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { FormattedMessageSanitized } from '../../FormattedMessageSanitized'
import ScrollAnimation from 'react-animate-on-scroll'
import VacancyTeaser from '../VacancyTeaser'
import VacancyIntro from '../VacancyIntro'
import Requirements from '../Requirements'
import Scrollindicator from '../../basic/Scrollindicator'
import FcseSpirit from '../FcseSpirit'
import WhatWeOffer from '../WhatWeOffer'
import Warning from '../Warning'
import messages from './FrontendDeveloperReactPage.lang'

import { ResponsibilitiesImage, QualificationImage } from "../images"

const FrontendDeveloperReactPage = () => {
  const { formatMessage } = useIntl()

  return (
    <div>
      <VacancyTeaser title={formatMessage(messages.pageTitle)} />
      <VacancyIntro
        title={formatMessage(messages.introTitle)}
        info={<FormattedMessageSanitized tagName="div" {...messages.introInfo} />}
        anchor={'VacancyQualifications'}
      />
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <FcseSpirit imgAlt="fcse summer event" />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <WhatWeOffer />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <Requirements
          image={ResponsibilitiesImage}
          imgAlt="guy starring"
          title={formatMessage(messages.responsibilitiesTitle)}
          requirements={[
            formatMessage(messages.responsibility1),
            formatMessage(messages.responsibility2),
            formatMessage(messages.responsibility3),
            formatMessage(messages.responsibility4),
            formatMessage(messages.responsibility5)
          ]}
        />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <Requirements
          reversed
          image={QualificationImage}
          imgAlt="working together"
          title={formatMessage(messages.qualificationsTitle)}
          id="VacancyQualifications"
          requirements={[
            formatMessage(messages.qualification1),
            formatMessage(messages.qualification2),
            formatMessage(messages.qualification3),
            formatMessage(messages.qualification4),
            formatMessage(messages.qualification5),
            formatMessage(messages.qualification6),
            formatMessage(messages.qualification7),
            formatMessage(messages.qualification8)
          ]}
        />
      </ScrollAnimation>
      <Warning />
      <Scrollindicator />
    </div>
  )
}

export default FrontendDeveloperReactPage
